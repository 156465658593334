import { animated } from 'react-spring'
import styles from './Heading.css'

export function HeadingPrimaryXxl({
  h,
  heading,
  animation = undefined,
  layoutClassName = undefined
}) {
  return (
    <HeadingBase
      className={cx(styles.componentPrimaryXxl, styles.primary, layoutClassName)}
      {...{ h, heading, animation }}
    />
  )
}

export function HeadingSecondaryXxl({
  h,
  heading,
  animation = undefined,
  layoutClassName = undefined
}) {
  return (
    <HeadingBase
      className={cx(styles.componentSecondaryXxl, styles.secondary, layoutClassName)}
      {...{ h, heading, animation }}
    />
  )
}

export function HeadingPrimaryXl({
  h,
  heading,
  animation = undefined,
  layoutClassName = undefined
}) {
  return (
    <HeadingBase
      className={cx(styles.componentPrimaryXl, styles.primary, layoutClassName)}
      {...{ h, heading, animation }}
    />
  )
}

export function HeadingSecondaryXl({
  h,
  heading,
  animation = undefined,
  layoutClassName = undefined
}) {
  return (
    <HeadingBase
      className={cx(styles.componentSecondaryXl, styles.secondary, layoutClassName)}
      {...{ h, heading, animation }}
    />
  )
}

export function HeadingPrimaryLg({
  h,
  heading,
  animation = undefined,
  layoutClassName = undefined
}) {
  return (
    <HeadingBase
      className={cx(styles.componentPrimaryLg, styles.primary, layoutClassName)}
      {...{ h, heading, animation }}
    />
  )
}

export function HeadingPrimaryMd({
  h,
  heading,
  animation = undefined,
  layoutClassName = undefined
}) {
  return (
    <HeadingBase
      className={cx(styles.componentPrimaryMd, styles.primary, layoutClassName)}
      {...{ h, heading, animation }}
    />
  )
}

export function HeadingSecondaryLg({
  h,
  heading,
  animation = undefined,
  layoutClassName = undefined
}) {
  return (
    <HeadingBase
      className={cx(styles.componentSecondaryLg, styles.secondary, layoutClassName)}
      {...{ h, heading, animation }}
    />
  )
}

export function HeadingPrimarySm({
  h,
  heading,
  animation = undefined,
  layoutClassName = undefined
}) {
  return (
    <HeadingBase
      className={cx(styles.componentPrimarySm, styles.primary, layoutClassName)}
      {...{ h, heading, animation }}
    />
  )
}

function HeadingBase({ h, heading, animation, className }) {
  const base = !isNaN(h) ? `h${h}` : 'strong'
  const Element = animation ? animated[base] : base

  const headingString = String(heading)

  const regex = /(?:\*\*(?<betweenAsterisks>[^*]+)\*\*)|(?:\|\|(?<betweenPipes>[^|]+)\|\|)|(?:##(?<betweenHashes>[^|]+)##)/g

  const isSplit = headingString.match(regex)
  const content = headingString.replace(regex, replaceSpecialCharacters)

  return (
    <Element
      style={animation}
      className={cx(styles.componentBase, className)}
      dangerouslySetInnerHTML={{ __html: content }}
      {...(isSplit ? { 'aria-label': normalizeHeading(headingString) } : {})}
    />
  )
}

function replaceSpecialCharacters(match, betweenAsterisks, betweenPipes, betweenHashes) {
  if (betweenAsterisks) return getAlternateFontElement(betweenAsterisks)
  if (betweenPipes) return getCircleEightElement(betweenPipes)
  if (betweenHashes) return getSuperscriptElement(betweenHashes)

  return match
}

function getAlternateFontElement(word) {
  return `<span class='${styles.alternateFontElement}'>${word}</span>`
}

function getCircleEightElement(word) {
  return `<span class='${styles.circle8FontElement}'>${word}</span>`
}

function getSuperscriptElement(word) {
  return `<sup class='${styles.superscriptElement}'>${word}</sup>`
}

function normalizeHeading(x) {
  return x.replaceAll('**', '').replaceAll('||', '')
}
